import React from 'react';
import { ThemeProvider } from '@emotion/react';
import Helmet from 'react-helmet';
import { theme } from '../theme';
import { FloatingTooltip } from './FloatingTooltip';
import { FloatingVideo } from './FloatingVideo/FloatingVideo';

interface Props {
  title?: string;
  description?: string;
  robots?: string;
  schema?: object;
  image?: string;
  url?: string;
  keywords?: string;
}

const COMMON_IMAGE = `${process.env.FTP_IMAGENS}images/home/fundo-home.jpg`;

export const Layout: React.FC<Props> = ({
  title,
  description,
  schema,
  image,
  url,
  children,
  keywords = '',
}) => {
  const keywordsContent = `${keywords} autos, autoscar, autus, classificados, car, site, carro, carros, veículo, veículos, automóveis, automóvel, anúncios, anúncio, meuveiculo, moto, motos, barco, barcos, caminhão, caminhões, comprar, vender, compra, venda, vende, peças, serviços, autopeças, peças para carros`;

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{title}</title>
        <html lang="pt-br" />
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywordsContent} />
        <meta name="image" content={image || COMMON_IMAGE} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Autoscar" />
        {url && <meta property="og:url" content={url} />}
        {title && <meta property="og:title" content={title} />}
        {description && <meta property="og:description" content={description} />}
        <meta property="og:image" content={image || COMMON_IMAGE} />
        {title && <meta name="twitter:title" content={title} />}
        {description && <meta name="twitter:description" content={description} />}
        <meta name="twitter:image" content={image || COMMON_IMAGE} />
        <link rel="image_src" href={image || COMMON_IMAGE} />
        <meta name="facebook-domain-verification" content="h8vgwmmxrzlyrfnnm5ai4d7oux0tni" />
        {/* iOS Splash Screen Start */}
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-touch-fullscreen" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/ios-splashscreens/iPhone_14_Pro_Max_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/ios-splashscreens/iPhone_14_Pro_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/ios-splashscreens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/ios-splashscreens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/ios-splashscreens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/ios-splashscreens/iPhone_11_Pro_Max__iPhone_XS_Max_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/ios-splashscreens/iPhone_11__iPhone_XR_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/ios-splashscreens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/ios-splashscreens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/ios-splashscreens/4__iPhone_SE__iPod_touch_5th_generation_and_later_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/ios-splashscreens/12.9__iPad_Pro_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/ios-splashscreens/11__iPad_Pro__10.5__iPad_Pro_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/ios-splashscreens/10.9__iPad_Air_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/ios-splashscreens/10.5__iPad_Air_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/ios-splashscreens/10.2__iPad_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/ios-splashscreens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/ios-splashscreens/8.3__iPad_Mini_portrait.png"
        />
        {/* iOS Splash Screen End */}
        {/* Schema.org tags */}
        {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
      </Helmet>
      <main className="trueno">
        {children}
        <FloatingVideo />
        <FloatingTooltip />
      </main>
    </ThemeProvider>
  );
};

Layout.defaultProps = {
  title: 'Compra e venda veículos novos, usados e seminovos | Autoscar',
  description:
    'Carros, motos, caminhões, ônibus, vans e veículos em geral. A Autoscar é o lugar perfeito para comprar ou vender seu veículo.',
  robots: 'all',
};
