import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby'; // Importa o navigate do Gatsby
import styles from './FloatingTooltip.module.css';

export const FloatingTooltip = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const storedState = sessionStorage.getItem('floatingTooltipClosed');
    if (storedState === 'true') {
      setIsVisible(false);
    }
  }, []);

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation(); // Evita redirecionamento ao clicar no "X"
    setIsVisible(false);
    sessionStorage.setItem('floatingTooltipClosed', 'true');
  };

  const handleRedirect = () => {
    navigate('/selecionar-tipo-de-anuncio'); // Redireciona usando o navigate do Gatsby
  };

  if (!isVisible) return null;

  return (
    <div className={styles.tooltipContainer} onClick={handleRedirect}>
      <div className={styles.tooltip}>
        <span>Anuncie já!</span>
        <button className={styles.closeButton} onClick={handleClose} aria-label="Fechar balão">
          ×
        </button>
      </div>
    </div>
  );
};
